import { corpInstalledbaseOverview } from "@telia/b2b-rest-client";
import { mapOverview } from "./overview-mapper";
import type { OverviewForFrontendType } from "./overview-mapper";

export const getOverview = async (
  scopeId: string,
  selectedOrganization: string,
  selectedAgreement: string
): Promise<OverviewForFrontendType> =>
  mapOverview(
    await corpInstalledbaseOverview.CorporateInstalledBaseOverviewService.getOverview(
      scopeId,
      // if the organization is alone, searching for empty array is faster than specifying the single value, but yields same result
      selectedOrganization === "ALL" ? [] : [selectedOrganization],
      selectedAgreement === "ALL" ? [] : [selectedAgreement]
    )
  );
