import {
  getTranslatedMainCategoryDisplayName,
  getTranslatedSubCategoryDisplayName,
  compareSubCategory,
  getMainCategories,
} from "@telia/b2b-product-categories-lib";

import type { CategoryId } from "@telia/b2b-product-categories-lib";

type MainCategoryFromBackendType = {
  name?: string;
  count?: number;
  categories?: {
    name?: string;
    count?: number;
  }[];
};

type OverviewFromBackendType = { mainCategories?: MainCategoryFromBackendType[] };

type SubCategoryForFrontendType = {
  name: CategoryId | "ALL";
  displayName: string;
};

type MainCategoryForFrontendType = {
  name: string;
  hasItems: boolean;
  displayName: string;
  categories: SubCategoryForFrontendType[];
};

export type OverviewForFrontendType = Record<string, MainCategoryForFrontendType>;

const mapMainCategory = (
  mainCategoryFromBackend: MainCategoryFromBackendType
): MainCategoryForFrontendType => ({
  name: mainCategoryFromBackend.name ?? "",
  hasItems: (mainCategoryFromBackend.count ?? 0) > 0,
  displayName: `${getTranslatedMainCategoryDisplayName(
    mainCategoryFromBackend.name as
      | "ALL"
      | "TELEPHONY"
      | "BROADBAND_AND_BUSINESS_NETWORK"
      | "SWITCH"
      | "SERVICE_AND_OTHER"
  )} (${mainCategoryFromBackend.count ?? 0})`,
  categories: (mainCategoryFromBackend.categories ?? []).map((category) => ({
    name: (category.name ?? "") as CategoryId,
    displayName: getSubCategoryDisplayName((category.name ?? "") as CategoryId, category.count ?? 0),
  })),
});

// Map the overview we get from backend, into exactly the format we need in frontend.
export const mapOverview = (
  overviewFromBackend: OverviewFromBackendType
): OverviewForFrontendType => {
  let mainCategories = {};

  // transform it in several ways:
  // - convert mainCategories from array to map for easier lookup
  // - lookup translations for subCategories
  // - convert undefined to empty arrays, empty string etc
  // - ensure subCategory ALL is first, and that the rest are alphabetically sorted
  // - strip out things that are not needed outside of this

  if (overviewFromBackend.mainCategories) {
    for (const mainCategory of overviewFromBackend.mainCategories) {
      if (mainCategory.name) {
        mainCategories[mainCategory.name] = mapMainCategory(mainCategory);
      }
    }
  }

  // add any empty categories that BE didn't provide
  for (const mainCategory of getMainCategories(true)) {
    if (!mainCategories[mainCategory.name]) {
      mainCategories[mainCategory.name] = mapMainCategory({
        name: mainCategory.name,
        count: 0,
        categories: [],
      });
    }
  }

  return mainCategories;
};

const getSubCategoryDisplayName = (name: CategoryId | "ALL", count: number): string =>
  `${getTranslatedSubCategoryDisplayName(name ?? "")} (${count ?? 0})`;

/**
 * take the list of subcategories, and the list of selections, and make sure every selected subcategory is included in the
 * list.
 */
export const mergeAndSortExistingWithSelectedSubCategories = (
  existingSubCategories: SubCategoryForFrontendType[],
  selectedSubCategories: (CategoryId | "ALL")[]
): SubCategoryForFrontendType[] => {
  let allSubCategories = [...existingSubCategories];
  for (const selectedSubCategory of selectedSubCategories) {
    if (
      existingSubCategories.every(
        (existingSubCategory) => existingSubCategory.name !== selectedSubCategory
      )
    ) {
      allSubCategories.push({
        name: selectedSubCategory,
        displayName: getSubCategoryDisplayName(selectedSubCategory, 0),
      });
    }
  }

  return allSubCategories.sort((firstSubCategory, secondSubCategory) =>
    compareSubCategory(
      firstSubCategory.name ?? "",
      secondSubCategory.name ?? "",
      firstSubCategory.displayName,
      secondSubCategory.displayName
    )
  );
};
