import { currentLanguage } from "@telia/b2b-i18n";

const categories = {
  BUSINESSNETWORK_LAN: {
    icon: "network",
    url: "foretagsnat-lan",
  },
  BUSINESSNETWORK_WAN: {
    icon: "network",
    url: "foretagsnat-wan",
  },
  CLOUD_SERVICES: {
    icon: "service-device",
    url: "moln-tjanster",
  },
  COLLECTIVE_TV_CHANNEL_PACKAGE: {
    icon: "tv",
    url: "tv",
  },
  CONF_MEET_SERVICES: {
    icon: "switch",
    url: "konferens-och-motestjanster",
  },
  CONTACT_CENTER: {
    icon: "switch",
    url: "kontakt-center",
  },
  DATACOM: {
    icon: "network",
    url: "datanet-vpn",
  },
  VPN: {
    icon: "internet",
    url: "datanet-vpn/vpn-info",
  },
  FIXED_BROADBAND: {
    icon: "broadband",
    url: "fast-bredband",
  },
  FIXED_VOICE: {
    icon: "phone",
    url: "fast-telefoni",
  },
  FUNCTION_ACD: {
    icon: "switch",
    url: "svarsgrupper",
  },
  FUNCTION_DATACOM: {
    icon: "network",
    url: "datacom",
  },
  FUNCTION_FIXED_EXT: {
    icon: "switch",
    url: "fast-anknytning",
  },
  FUNCTION_MOBILE_EXT: {
    icon: "switch",
    url: "mobila-anknytningar",
  },
  FUNCTION_MOBILE: {
    icon: "phone",
    url: "mobila-abonnemang",
  },
  FUNCTION_OTHER: {
    icon: "service-device",
    url: "ovrigt",
  },
  FUNCTION_TERMINAL: {
    icon: "end-user",
    url: "terminaler",
  },
  GOODS: {
    icon: "phone",
    url: "telefoner-och-ovrig-hardvara",
  },
  INSURANCE: {
    icon: "service-device",
    url: "insurance",
  },
  MOBILE_BROADBAND: {
    icon: "broadband",
    url: "mobilt-bredband",
  },
  MOBILE_M2M: {
    icon: "service-device",
    url: "machine-to-machine",
  },
  SMS_SERVICES: {
    icon: "phone",
    url: "sms-tjanster",
  },
  MOBILE_CORP_DATAPOOL: {
    icon: "phone",
    url: "foretag-datapool",
  },
  MOBILE_VOICE: {
    icon: "phone",
    url: "mobil-telefoni",
  },
  MOBILITY_MANAGEMENT: {
    icon: "network",
    url: "mobility-management",
  },
  NUMBER_SERVICES: {
    icon: "phone",
    url: "nummer-tjanster",
  },
  BUSINESSNETWORK_OTHER: {
    icon: "network",
    url: "ovrigt-foretagsnat",
  },
  SECURITY: {
    icon: "network",
    url: "sakerhet",
  },
  SERVICE_MANAGEMENT: {
    icon: "service-device",
    url: "service-tjanster",
  },
  SWITCHBOARD_ACCESS: {
    icon: "switch",
    url: "vaxel-access",
  },
  SWITCHBOARD_EXT: {
    icon: "switch",
    url: "vaxel-anknytning",
  },
  SWITCHBOARD_UCS: {
    icon: "switch",
    url: "uc-vaxel",
  },
};

export declare type CategoryId = keyof typeof categories;

declare type Category = {
  icon: string;
  url: string;
};

export const getCategory = (id: CategoryId): Category | undefined => categories[id];

export const getCategoryUrl = (id: CategoryId): Category["url"] | undefined => getCategory(id)?.url;

export const getCategoryIcon = (id: CategoryId): Category["icon"] | undefined =>
  getCategory(id)?.icon;

/**
 * Use to sort subcategories (product categories).
 * Any subcategories with id "ALL" will be placed first, the rest will be sorted alphabetically.
 */
export const compareSubCategory = (
  firstId: CategoryId | "ALL",
  secondId: CategoryId | "ALL",
  firstTranslatedDisplayName: string,
  secondTranslatedDisplayName: string
): number => {
  if (firstId === "ALL" && secondId === "ALL") {
    return 0;
  } else if (firstId === "ALL" && secondId !== "ALL") {
    return -1;
  } else if (firstId !== "ALL" && secondId === "ALL") {
    return 1;
  } else {
    return firstTranslatedDisplayName.localeCompare(
      secondTranslatedDisplayName,
      currentLanguage(),
      {
        sensitivity: "base",
      }
    );
  }
};
