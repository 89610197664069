import { logError } from "@telia/b2x-logging";
import { corpInstalledbaseOverview } from "@telia/b2b-rest-client";
import { mapAgreements } from "./agreement-mapper";
import type { AgreementsFromBackendType, AgreementsForFrontendType } from "./agreement-mapper";

const cachedPromises: Record<string, Promise<AgreementsFromBackendType>> = {};

const getCachedBackendPromise = async (
  scopeId: string,
  selectedOrganization: string
): Promise<AgreementsFromBackendType> => {
  const cacheKey = scopeId + "_" + selectedOrganization;

  if (!cachedPromises[cacheKey]) {
    cachedPromises[cacheKey] =
      corpInstalledbaseOverview.CorporateInstalledBaseOverviewService.getAgreements(
        scopeId,
        selectedOrganization === "ALL" ? [] : [selectedOrganization]
      ).catch(() => {
        logError(
          "b2b-manage-overview",
          "Failed to get agreements, will behave as if there is none"
        );
        return [];
      });
  }
  return cachedPromises[cacheKey];
};

/**
 * This should never throw an exception!
 * If a backend error occurs, it is logged and behaves as if there are no agreements
 * @param scopeId
 * @param selectedOrganization
 * @param t
 * @returns
 */
export const getAgreements = async (
  scopeId: string,
  selectedOrganization: string,
  t: (key: string) => string
): Promise<AgreementsForFrontendType> => {
  // note - not caching the mapped version, not much benefit and relies on language
  return mapAgreements(await getCachedBackendPromise(scopeId, selectedOrganization), t);
};
