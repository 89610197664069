import type { SubscriptionUI } from "@telia/b2b-rest-client/dist/corp-installedbase-list-pagination/models/SubscriptionUI";
import {
  LinkCellData,
  StatusBadgeCellData,
  TextCellData,
} from "@telia/b2x-table/dist/types/components/table-cell/cell-data";
import { TableCellValue } from "@telia/b2x-table/dist/types/components/table-cell/table-cell-component-map";
import type { TableManagerColumn } from "@telia/b2x-table/dist/types/components/table-column-manager/table-column-manager";
import {
  getDetailsPageUrl,
  getStatus,
  getTranslatedAndSortedStatuses,
} from "@telia/b2b-subscription-common";
import { CategoryId, MainCategory as Category } from "@telia/b2b-product-categories-lib";
import type { StatusId, TranslatedStatus } from "@telia/b2b-subscription-common";
import { TableCellType } from "../b2x-table.enum";

export interface Column extends TableManagerColumn {
  attribute: keyof SubscriptionUI;
  categories: CategoryType[];
  resolve?: (subscription: SubscriptionUI, column: Column, scopeId: string) => TableCellValue;
  columnGroup?: ColumnGroupsType;
}

const ColumnGroups = {
  BASIC_INFORMATION: "BASIC_INFORMATION",
  STATUS: "STATUS",
  ADDRESS: "ADDRESS",
  CONNECTIONS: "CONNECTIONS",
  ORGANISATION: "ORGANISATION",
  HARDWARE: "HARDWARE",
} as const;

export type ColumnGroupsType = (typeof ColumnGroups)[keyof typeof ColumnGroups];
export type CategoryType = (typeof Category)[keyof typeof Category];

const columns: Column[] = [
  {
    title: "SUBSCRIPTION",
    attribute: "subscriptionIdentifier",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.BASIC_INFORMATION,
    type: TableCellType.SSPA_LINK,
    isChecked: true,
    disabled: true,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "SUBSCRIPTION",
    },
    resolve: (
      subscription: SubscriptionUI,
      { attribute }: Column,
      scopeId: string
    ): LinkCellData => {
      return {
        href: getDetailsPageUrl(
          scopeId,
          subscription.id as string,
          subscription.productCategory as CategoryId,
        ),
        content: subscription[attribute] as string,
      };
    },
  },
  {
    title: "NAME",
    attribute: "name",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.BASIC_INFORMATION,
    type: TableCellType.TRUNCATABLE_FLEXIBLE_WIDTH_TEXT,
    isChecked: true,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "NAME",
    },
  },
  {
    title: "ALIAS",
    attribute: "alias",
    categories: [Category.ALL, Category.BROADBAND_AND_BUSINESS_NETWORK, Category.SERVICE_AND_OTHER],
    columnGroup: ColumnGroups.BASIC_INFORMATION,
    type: TableCellType.TRUNCATABLE_FLEXIBLE_WIDTH_TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "ALIAS",
    },
  },
  {
    title: "OFFERING",
    attribute: "offering",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.BASIC_INFORMATION,
    type: TableCellType.TRUNCATABLE_FLEXIBLE_WIDTH_TEXT,
    isChecked: true,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "OFFERING",
    },
  },
  {
    title: "START_DATE",
    attribute: "startDate",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.STATUS,
    type: TableCellType.TRUNCATABLE_FLEXIBLE_WIDTH_TEXT,
    isChecked: true,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "START_DATE",
    },
  },
  {
    title: "COMMITMENT",
    attribute: "commitment",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.STATUS,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "COMMITMENT",
    },
  },
  {
    title: "ANNULL_DATE",
    attribute: "annulmentDate",
    categories: [
      Category.ALL,
      Category.TELEPHONY,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
    ],
    columnGroup: ColumnGroups.STATUS,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "ANNULL_DATE",
    },
  },
  {
    title: "STATUS",
    attribute: "status",
    categories: [Category.ALL, Category.BROADBAND_AND_BUSINESS_NETWORK],
    columnGroup: ColumnGroups.STATUS,
    type: TableCellType.STATUS_BADGE,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "enum",
      label: "STATUS",
      values: getTranslatedAndSortedStatuses().map((status: TranslatedStatus) => ({
        value: status.id,
        displayName: status.translatedName,
      })),
      noSelectionDisplayName: "STATUS",
    },
    resolve: (subscription: SubscriptionUI, { attribute }: Column): StatusBadgeCellData => {
      const status = getStatus(subscription[attribute] as StatusId);

      return {
        variant: status.variant,
        allyLabel: status.accessibleLabel,
        content: status.text,
      };
    },
  },
  {
    title: "STREET_ADDRESS",
    attribute: "streetAddress",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ADDRESS,
    type: TableCellType.TEXT,
    isChecked: true,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "STREET_ADDRESS",
    },
  },
  {
    title: "CITY",
    attribute: "city",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ADDRESS,
    type: TableCellType.TRUNCATABLE_FLEXIBLE_WIDTH_TEXT,
    isChecked: true,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "CITY",
    },
  },
  {
    title: "ZIPCODE",
    attribute: "zipCode",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ADDRESS,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "ZIPCODE",
    },
    resolve: (subscription: SubscriptionUI, { attribute }: Column): TextCellData => {
      return (subscription[attribute] as string)?.replace(/^(\d{3})(\d{2})$/, "$1 $2");
    },
  },
  {
    title: "COUNTRY",
    attribute: "country",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ADDRESS,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "COUNTRY",
    },
  },
  {
    title: "MOBILE_NUMBER",
    attribute: "mobilenumber",
    categories: [Category.ALL, Category.TELEPHONY],
    columnGroup: ColumnGroups.CONNECTIONS,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "MOBILE_NUMBER",
    },
  },
  {
    title: "ICC_NUMBER",
    attribute: "iccNumber",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.CONNECTIONS,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "ICC_NUMBER",
    },
  },
  {
    title: "GPRS_SERVICE",
    attribute: "gprsService",
    categories: [
      Category.ALL,
      Category.TELEPHONY,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
    ],
    columnGroup: ColumnGroups.CONNECTIONS,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "GPRS_SERVICE",
    },
  },
  {
    title: "SWITCH",
    attribute: "mobileExchangeService",
    categories: [Category.ALL, Category.TELEPHONY],
    columnGroup: ColumnGroups.CONNECTIONS,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "SWITCH",
    },
  },
  {
    title: "AGREEMENT_NUMBER",
    attribute: "agreement", // TEMP: until the "agreementNumber" attr is BE ready
    categories: [
      Category.ALL,
      Category.TELEPHONY,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
    ],
    columnGroup: ColumnGroups.ORGANISATION,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "AGREEMENT_NUMBER",
    },
  },
  {
    title: "ORGANIZATION_NAME",
    attribute: "organizationName",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ORGANISATION,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "ORGANIZATION_NAME",
    },
  },
  {
    title: "UNIT_NAME",
    attribute: "xtasUnitName",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ORGANISATION,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "UNIT_NAME",
    },
  },
  {
    title: "COST_LOCATION",
    attribute: "xtasCostLocationName",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ORGANISATION,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "COST_LOCATION",
    },
  },
  {
    title: "SUBSCRIPTION_REFERENCE",
    attribute: "subscriptionReference",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ORGANISATION,
    type: TableCellType.TRUNCATABLE_TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "SUBSCRIPTION_REFERENCE",
    },
  },
  {
    title: "BILLING_REFERENCE",
    attribute: "billingReference",
    categories: [
      Category.ALL,
      Category.TELEPHONY,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
    ],
    columnGroup: ColumnGroups.ORGANISATION,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "BILLING_REFERENCE",
    },
  },
  {
    title: "BILLING_ACCOUNT",
    attribute: "billingAccount",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
      Category.SWITCH,
      Category.TELEPHONY,
    ],
    columnGroup: ColumnGroups.ORGANISATION,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "BILLING_ACCOUNT",
    },
  },
  {
    title: "MANUFACTURER",
    attribute: "hardwareVendor",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.TELEPHONY,
      Category.SERVICE_AND_OTHER,
    ],
    columnGroup: ColumnGroups.HARDWARE,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "MANUFACTURER",
    },
  },
  {
    title: "HARDWARE_MODEL",
    attribute: "hardwareModel",
    categories: [
      Category.ALL,
      Category.TELEPHONY,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
    ],
    columnGroup: ColumnGroups.HARDWARE,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "HARDWARE_MODEL",
    },
  },
  {
    title: "IMEI",
    attribute: "hardwareImei",
    categories: [
      Category.ALL,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.TELEPHONY,
      Category.SERVICE_AND_OTHER,
    ],
    columnGroup: ColumnGroups.HARDWARE,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "IMEI",
    },
  },
  {
    title: "HARDWARE_LAST_USED",
    attribute: "hardwareLastUsed",
    categories: [
      Category.ALL,
      Category.TELEPHONY,
      Category.BROADBAND_AND_BUSINESS_NETWORK,
      Category.SERVICE_AND_OTHER,
    ],
    columnGroup: ColumnGroups.HARDWARE,
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "HARDWARE_LAST_USED",
    },
  },
  {
    title: "ACCESS_FORM",
    attribute: "datacomAccess",
    categories: [Category.ALL, Category.BROADBAND_AND_BUSINESS_NETWORK],
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "ACCESS_FORM",
    },
  },
  {
    title: "ACCESS_NUMBER",
    attribute: "accessNumber",
    categories: [Category.ALL, Category.TELEPHONY],
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "ACCESS_NUMBER",
    },
  },
  {
    title: "SPEED",
    attribute: "speed",
    categories: [Category.ALL, Category.BROADBAND_AND_BUSINESS_NETWORK],
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "SPEED",
    },
  },
  {
    title: "CONTACT_PERSON",
    attribute: "contactPerson",
    categories: [Category.ALL, Category.BROADBAND_AND_BUSINESS_NETWORK, Category.TELEPHONY],
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "CONTACT_PERSON",
    },
  },
  {
    title: "SERVICE_AGREEMENT",
    attribute: "serviceAgreementName",
    categories: [Category.ALL, Category.BROADBAND_AND_BUSINESS_NETWORK],
    type: TableCellType.TEXT,
    isChecked: false,
    disabled: false,
    sortable: {
      sortIconName: "sorter",
    },
    filterable: {
      type: "text",
      label: "SERVICE_AGREEMENT",
    },
  },
];

export default columns;
